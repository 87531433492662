import styled from "styled-components";

export const Logo = styled.a`
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    padding-right: 12px;
  }
`;
